import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  SubtitleTitle,
  ListItem,
  ImgWrap,
  Img,
} from "./InfoElements";

export const index = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  descriptionAlt,
  descriptionTitle,
  descriptionList,
  lightTextTopLine,
  alt,
  img,
}) => {
  const renderDescriptionAlt = () => {
    return (
      Array.isArray(descriptionAlt) &&
      descriptionAlt
        .map((item, index) =>
          item.text ? (
            <Subtitle key={index} darkText={darkText}>
              {item.text}
            </Subtitle>
          ) : null
        )
        .filter(Boolean)
    );
  };

  const renderDescriptionList = () => {
    return (
      Array.isArray(descriptionList) &&
      descriptionList.map((item, index) => (
        <ListItem key={index} darkText={darkText}>
          {item.text}
        </ListItem>
      ))
    );
  };

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                {topLine && (
                  <TopLine lightTextTopLine={lightTextTopLine}>
                    {topLine}
                  </TopLine>
                )}
                {headline && (
                  <Heading lightText={lightText}>{headline}</Heading>
                )}
                {description && (
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                )}
                {descriptionTitle && (
                  <SubtitleTitle darkText={darkText}>
                    {descriptionTitle}
                  </SubtitleTitle>
                )}
                {renderDescriptionAlt()}
                {renderDescriptionList()}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}></Img>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default index;
