import React from "react";
import {
  BottomInfoSectionContainer,
  BottomInfoSectionBackground,
  BottomInfoSectionText,
  CenteredContainer,
} from "./BottomInfoSection.js";

const index = () => {
  return (
    <BottomInfoSectionBackground>
      <BottomInfoSectionContainer>
        <CenteredContainer>
          <BottomInfoSectionText>
            Înființată în 2010, societatea s-a adaptat schimbărilor de cerințe
            de pe piața confecțiilor metalice, reușind să satisfacă nevoile unui
            număr de clienți ce crește constant. Acest progres se datorează
            profesionalismului dovedit în relațiile cu clienții, dar și prin
            reinvestirea profitului și achiziția de echipamente performante.
          </BottomInfoSectionText>
        </CenteredContainer>
      </BottomInfoSectionContainer>
    </BottomInfoSectionBackground>
  );
};

export default index;
