import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavbarLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { FooterLogo } from "../Footer/FooterElements";
import logo from "../../images/logo_white.webp";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavbarLogo to="/" onClick={toggleHome}>
              <FooterLogo>
                <img
                  src={logo}
                  alt="logo"
                  className="logo"
                  height="100%"
                  width="100%"
                />
              </FooterLogo>
            </NavbarLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to="despre"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  a
                  exact="true"
                  class="sc-eTNRI kYnYLA"
                >
                  Despre noi
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="galerie"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-150}
                  a
                  exact="true"
                  class="sc-eTNRI kYnYLA"
                >
                  Galerie
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavLinks
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                a
                exact="true"
                class="sc-eTNRI kYnYLA"
              >
                <NavBtnLink>Contactează-ne</NavBtnLink>
              </NavLinks>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
