import styled from "styled-components";
import { motion } from "framer-motion/dist/framer-motion";
import Select from "react-select";

export const GalleryBackground = styled(motion.div)`
  background: #f9f9f9;
`;

export const GalleryContainer = styled(motion.div)`
  cursor: grab;
  overflow: hidden;
  margin: 0 10%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 935px) {
    background: #f9f9f9;
    cursor: grab;
    overflow: hidden;
    margin: 0 10%;
    max-height: 100vh;
    max-width: 100vw;
  }
`;

export const GalleryInnerCarousel = styled(motion.div)`
  display: flex;
  background: #f9f9f9;

  @media screen and (max-width: 935px) {
    background-color: transparent;
  }
`;

export const GalleryItem = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: 20px;

  @media screen and (max-width: 935px) {
    img {
      width: 20rem;
      height: 20rem;
      padding: 25px;
      object-fit: cover;
      pointer-events: none;
    }
  }
`;

export const GalleryImage = styled.img`
  width: 20rem;
  height: 20rem;
  border-radius: 2rem;
  pointer-events: none;

  @media screen and (max-width: 935px) {
    margin-top: -60px;
    border-radius: 5rem;
    pointer-events: none;
  }
`;

export const GalleryImageWide = styled.img`
  width: 30rem;
  height: 20rem;
  border-radius: 2rem;
  pointer-events: none;

  @media screen and (max-width: 935px) {
    width: 30rem;
    height: 20rem;
    margin-top: -60px;
    border-radius: 5rem;
    pointer-events: none;
  }
`;

export const GalleryTitle = styled.h1`
  color: #171b25;
  font-weight: 600;
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 935px) {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SelectMenu = styled(Select)`
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }

  .dropdown-select__control.dropdown-select__control--is-focused {
    font-size: 16px !important;
  }
`;
