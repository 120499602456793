import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: #f9f9f9;
  margin-top: -150px;

  @media and screen (max-width: 935px) {
    padding: 50px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 935px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 935px) {
    padding: 0 40px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 935px) {
    grid-template-areas:
      "col2"
      "col1";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  margin-bottom: -80px;

  @media screen and (max-width: 935px) {
    margin-bottom: 0 -160px;
    padding: 0 10px;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  margin-bottom: -80px;

  @media screen and (max-width: 935px) {
    margin-bottom: 0 -160px;
    padding: 0 10px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 935px) {
    padding-bottom: 150px;
  }
`;

export const TopLine = styled.p`
  color: ${({ lightTextTopLine }) =>
    lightTextTopLine ? "#f7f8fa" : "#010606"};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter: spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media screen and (max-width: 935px) {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 32px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    justify-items: center;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 935px) {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    justify-items: center;
    line-height: 1.2;
  }
`;

export const HeadingAlt = styled.h1`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 400;
  color: #010606;

  @media screen(max-width: 480px) {
    font-size: 32px;
  }

  @media screen and (max-width: 935px) {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    justify-items: center;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};

  @media screen and (max-width: 935px) {
    max-width: 440px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    line-height: 2em;
    margin-top: 50px;
    text-align: left;
    justify-items: left;
  }
`;

export const SubtitleTitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};

  @media screen and (max-width: 935px) {
    max-width: 440px;
    margin-bottom: 35px;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 24px;
    text-align: left;
    justify-items: center;
    line-height: 1.2;
  }
`;

export const ListItem = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};

  @media screen and (max-width: 935px) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 935px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  opacity: 0.2;

  @media screen and (max-width: 935px) {
    width: auto;
    max-width: 100%;
    height: auto;
    opacity: 0.2;
  }
`;
