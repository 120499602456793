import React from "react";
import { FaFacebook, FaHeart, FaInstagram } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import Flags from "country-flag-icons/react/3x2";
import logo from "../../images/logo.webp";
import {
  FooterContainer,
  FooterWrapper,
  FooterLinksContainer,
  FooterLinkWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  ContactList,
  DevelopedBy,
  FooterLogo,
  SocialMediaLink,
} from "./FooterElements";

const index = ({ toggle }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinksContainer>
          <FooterLinkWrapper>
            <FooterLinkItems hideOnMobile={true}>
              <FooterLinkTitle>Meniu</FooterLinkTitle>
              <FooterLink
                to="/"
                onClick={toggleHome}
                a
                class="sc-hIPBNq jMtrGg"
              >
                Acasă
              </FooterLink>
              <FooterLink
                to="despre"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                a
                class="sc-hIPBNq jMtrGg"
              >
                Despre noi
              </FooterLink>
              <FooterLink
                to="galerie"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-150}
                a
                class="sc-hIPBNq jMtrGg"
              >
                Galerie
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact</FooterLinkTitle>
              <ContactList
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/place/Bac%C4%83u/@46.5640129,26.8264597,12z/data=!3m1!4b1!4m6!3m5!1s0x40b570183882e30b:0x2659d504aae13301!8m2!3d46.5670437!4d26.9145748!16zL20vMGdwZnY?entry=ttu"
                  )
                }
              >
                Bacău, România
              </ContactList>
              <ContactList>sintexmet@yahoo.com</ContactList>
              <ContactList>sintexmet@gmail.com</ContactList>
              <ContactList>0740012004</ContactList>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Urmărește-ne</FooterLinkTitle>
              <SocialMediaLink to="https://www.facebook.com/profile.php?id=61556351717106">
                Facebook
              </SocialMediaLink>
              <SocialMediaLink to="https://www.instagram.com/sintexmet2010/">
                Instagram
              </SocialMediaLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <FooterLogo to="/" onClick={toggleHome}>
              <img
                src={logo}
                alt="logo"
                className="logo"
                height="100%"
                width="100%"
              />
            </FooterLogo>{" "}
            <WebsiteRights>
              © Copyright S.C. Sintex MET S.R.L {new Date().getFullYear()}.
              Toate drepturile rezervate.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/profile.php?id=61556351717106"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.instagram.com/sintexmet2010/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
        <DevelopedBy>
          Developed with <FaHeart color={"red"} size={"10px"} /> in{" "}
          <Flags.RO width="15px" />
        </DevelopedBy>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default index;
