export const homeObjOne = {
  id: "despre",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  lightTextTopLine: false,
  topLine: "Cine suntem?",
  headline: "S.C. Sintex MET S.R.L.",
  headlineAlt: "Firmă specializată pe două linii de business",
  description:
    "Producție de confecții metalice din fier forjat, ce comercializează porți, garduri, mobilier din metal, ornamente și decorațiuni pentru case, grădini, restaurante și terase.",
  descriptionAlt: [
    {
      text: "În completarea gamei de produse oferite, puteți alege și serviciile noastre de vopsire în câmp electrostatic sau sablări pe lemn și metal.",
    },
  ],
  imgStart: false,
  img: require("../../images/text01.webp"),
  alt: "sintex met",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjTwo = {
  id: "despre",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  lightTextTopLine: false,
  topLine: "",
  headline: "",
  description: "",
  descriptionTitle:
    "Decorări panouri publicitare de diferite dimensiuni și înălțimi",
  descriptionAlt: [],
  descriptionList: [
    { text: "• Unipoluri (10x4 sau 15x5m)" },
    { text: "• BKL-uri (4x3m, 3x2, 6x3)" },
    { text: "• CL-uri tip afișsaj stradal" },
    {
      text: "• Casete pe totemuri (la diferite înălțimi, cu alpinist și macara)",
    },
  ],
  imgStart: true,
  img: require("../../images/text02.webp"),
  alt: "sintex met",
  dark: true,
  primary: true,
  darkText: true,
};
