import React, { useState, useEffect } from "react";
import Video from "../../videos/video.mp4";
import { Button } from "../ButtonElement";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroH2,
  HeroBtnWrapper,
  VideoOverlay,
  ArrowDown,
  VideoGradient,
} from "./HeroElements";

export const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  useEffect(() => {
    const handleScroll = () => {};

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeroContainer>
      <VideoGradient />
      <VideoOverlay />
      <HeroBg>
        <VideoBg
          autoPlay
          loop
          muted
          src={Video}
          type="video/mp4"
          playsInline
        ></VideoBg>
      </HeroBg>
      <HeroContent>
        <HeroH1>S.C. Sintex MET S.R.L.</HeroH1>
        <HeroH2>Din 2010 facem artă din fier</HeroH2>
        <HeroBtnWrapper>
          <Button
            to="despre"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            light="true"
            smooth={true}
            duration={900}
            spy={true}
            exact="true"
            offset={-80}
            a
            primary="true"
            light="true"
            exact="true"
            class="sc-bZHSRq lbiJpy"
          >
            Află mai multe {<ArrowDown />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};
