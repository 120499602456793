import styled from "styled-components";

export const BottomInfoSectionBackground = styled.div`
  margin-top: -150px;
  background: #f9f9f9;
`;

export const BottomInfoSectionText = styled.h1`
  color: #171b25;
  font-weight: 450;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 935px) {
    font-size: 16px;
    line-height: 1.8em;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f9f9f9;
  margin-bottom: 100px;
`;

export const BottomInfoSectionContainer = styled.div`
  cursor: grab;
  overflow: hidden;
  margin: 0 10%;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;

  @media screen and (max-width: 935px) {
    background: #f9f9f9;
    cursor: grab;
    overflow: hidden;
    margin: 0 10%;
    max-height: 100vh;
    max-width: 100vw;
  }
`;
