import styled from "styled-components";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { GoDot } from "react-icons/go";

export const HeroContainer = styled.div`
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0 30px;
  height: 840px;
  position: relative;
  z-index: 1;
  margin-bottom: 150px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%),
      rgba(0, 0, 0, 0.6) 100%),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  postion: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.4);
`;

export const VideoGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4)
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 10%,
      rgba(247, 248, 250, 0.1) 40%,
      rgba(247, 248, 250, 0.5) 75%,
      rgba(247, 248, 250, 1) 100%
    )
    repeat scroll 0 0;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroH2 = styled.h2`
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-top: 16px;
  font-size: 20px;
`;

export const Dot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;
`;

export const DotIcon = styled(GoDot)`
  color: ${({ primary }) => (primary ? "#3e236e" : "#BDBDBD")};
  align-items: center;
  margin-top: 5px;
  font-size: 20px;
`;
