import React from "react";
import {
  ContactBackground,
  ContactContainer,
  ContactWrapper,
  ContactTitle,
  ContactContent,
  ContactTitleImg,
  ContactBtn,
  ContactBtnLink,
  ContactForm,
  ContactFormInput,
  ContactFormTextArea,
  ContactFormLabel,
  ContactSpan,
} from "./ConctactElements.js";
import msg from "../../images/msg-icon.webp";

const index = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Mesajul se trimite....");
    const formData = new FormData(event.target);

    formData.append("access_key", process.env.REACT_APP_WEB3FORMS_API_KEY);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Mesajul a fost trimis cu success!");
      event.target.reset();
    } else {
      console.log("Mesajul nu a putut fi trimis!", data);
      setResult(data.message);
      event.target.reset();
    }
  };

  return (
    <ContactBackground>
      <ContactContainer id="contact">
        <ContactWrapper>
          <ContactTitle>
            Contactează-ne
            <ContactTitleImg src={msg} alt="email" />
          </ContactTitle>
          <ContactContent>
            Nu ezita să ne contactezi, folosind formularul alăturat sau
            contactele de mai jos. Feedback-ul dumneavoastră este important
            pentru noi, întrucât vrem să îmbunătățim constant serviciile nostre.
          </ContactContent>
        </ContactWrapper>
        <ContactWrapper>
          <ContactForm onSubmit={onSubmit}>
            <ContactFormLabel>Nume și Prenume</ContactFormLabel>
            <ContactFormInput
              type="text"
              name="nume"
              placeholder=""
              required
            ></ContactFormInput>
            <ContactFormLabel>Telefon</ContactFormLabel>
            <ContactFormInput
              type="text"
              name="telefon"
              placeholder=""
              required
            ></ContactFormInput>
            <ContactFormLabel>Adresă de e-mail</ContactFormLabel>
            <ContactFormInput
              type="email"
              name="email"
              placeholder=""
              required
            ></ContactFormInput>
            <ContactFormTextArea
              name="message"
              rows="6"
              placeholder="Mesajul dvs."
              required
            ></ContactFormTextArea>
            <ContactBtn>
              <ContactBtnLink>Trimite mesaj</ContactBtnLink>
            </ContactBtn>
          </ContactForm>
          <ContactSpan>{result}</ContactSpan>
        </ContactWrapper>
      </ContactContainer>
    </ContactBackground>
  );
};

export default index;
