import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const FooterContainer = styled.footer`
  background: #f9f9f9;
`;

export const FooterWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 935px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 935px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    align-items: start;
    gap: 20px;
    width: 100%;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  box-sizing: border-box;
  color: #101522;

  @media screen and (max-width: 935px) {
    width: calc(100% - 32px);
    align-items: flex-start;
    display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "flex")};
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
  text-align: left;

  @media screen and (max-width: 935px) {
    font-size: 12px;
  }
`;

export const FooterLink = styled(LinkS)`
  color: #101522;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: #546e7a;
    transition: 0.3s ease-out;
  }
`;

export const SocialMediaLink = styled(Link)`
  color: #101522;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: #546e7a;
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 935px) {
    padding: 0;
    font-size: 12px;
    text-align: left;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;

  @media screen and (max-width: 935px) {
    justify-content: center;
    align-items: center;
  }
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 935px) {
    flex-direction: column;

    * {
      width: 100%;
      text-align: center;
    }
  }
`;

export const SocialLogo = styled(Link)`
  color: #101522;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const FooterLogo = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const WebsiteRights = styled.small`
  color: #101522;
  margin-bottom: 16px;
`;

export const DevelopedBy = styled.small`
  color: #101522;
  justify-content: center;
  align-items: center;
  margin-top: 0 auto;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 80px;
`;

export const SocialIconLink = styled.a`
  color: #101522;
  font-size: 24px;
`;

export const ContactList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #101522;
  margin-bottom: 0.5rem;
  text-decoration: none;

  &:hover {
    color: #546e7a;
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 935px) {
    font-size: 12px;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;
