import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElements";

const index = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="despre" onClick={toggle} a class="sc-jTQCzO dpSZpT">
            Despre
          </SidebarLink>
          <SidebarLink to="galerie" onClick={toggle} a class="sc-jTQCzO dpSZpT">
            Galerie
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute
            to="contact"
            onClick={toggle}
            a
            class="sc-iBdnpw dRdsfo"
          >
            Contactează-ne
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default index;
