const categoryOptions = [
  { value: "", label: "Toate produsele" },
  { value: "Porti și garduri", label: "Porti și garduri" },
  {
    value: "Scări interioare și balustrade",
    label: "Scări interioare și balustrade",
  },
  {
    value: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    label: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
  },
  { value: "Sablări pe metal și lemn", label: "Sablări pe metal și lemn" },
];

export default categoryOptions;
