import React, { useEffect, useRef, useState } from "react";
import categories from "./images";
import categoryOptions from "./category_options";
import {
  GalleryBackground,
  GalleryContainer,
  GalleryImage,
  GalleryImageWide,
  GalleryInnerCarousel,
  GalleryItem,
  GalleryTitle,
  CenteredContainer,
  SelectMenu,
} from "./GalleryElements.js";

const Gallery = () => {
  const carouselRef = useRef();
  const [constraint, setConstraint] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredImages, setFilteredImages] = useState(categories);

  useEffect(() => {
    const updateWidth = () => {
      const carousel = carouselRef.current;
      if (carousel) {
        const fullWidth = carousel.scrollWidth - carousel.offsetWidth;
        setConstraint(-fullWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, [filteredImages]);

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.value === "") {
        setFilteredImages(categories);
      } else {
        const filtered = categories.filter(
          (image) => image.category === selectedCategory.value
        );
        setFilteredImages(filtered);
      }
    } else {
      setFilteredImages(categories);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollLeft = 0;
    }
  }, [filteredImages]);

  const isWideImage = (image) => image.includes("_wide");

  return (
    <GalleryBackground>
      <GalleryContainer
        ref={carouselRef}
        whileTap={{ cursor: "grabbing" }}
        id="galerie"
      >
        <GalleryTitle>Galerie produse</GalleryTitle>
        <CenteredContainer>
          <SelectMenu
            options={categoryOptions}
            value={categoryOptions.find(
              (option) =>
                selectedCategory && option.value === selectedCategory.value
            )}
            onChange={setSelectedCategory}
            isClearable={false}
            placeholder="Alege o categorie"
          />
        </CenteredContainer>
        <GalleryInnerCarousel
          key={selectedCategory ? selectedCategory.value : "all"}
          drag="x"
          dragConstraints={{ right: 0, left: constraint }}
        >
          {filteredImages.map((image) => (
            <GalleryItem key={image.src}>
              <CenteredContainer>
                {isWideImage(image.src) ? (
                  <GalleryImageWide
                    src={image.src}
                    alt={image.alt}
                    className="wide"
                    loading="eager"
                  />
                ) : (
                  <GalleryImage
                    src={image.src}
                    alt={image.alt}
                    loading="eager"
                  />
                )}
              </CenteredContainer>
            </GalleryItem>
          ))}
        </GalleryInnerCarousel>
      </GalleryContainer>
    </GalleryBackground>
  );
};

export default Gallery;
