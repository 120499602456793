import styled from "styled-components";

export const ContactBackground = styled.div`
  background: #f9f9f9;

  @media screen and (max-width: 935px) {
    margin-top: -80px;
  }
`;

export const ContactContainer = styled.div`
  margin: 80px auto;
  //   padding: 48px 24px;
  padding-top: 100px;
  max-width: 90%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 950px) {
    display: flex;
    padding: 48px 24px;
    flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;
  }
`;

export const ContactWrapper = styled.div`
  flex-basis: 48%;
  color: #676767;

  @media screen and (max-width: 950px) {
    align-items: center;
    text-align: center;
    padding: 14px 10px;
  }
`;

export const ContactForm = styled.form`
  flex-basis: 48%;
  color: #676767;

  @media screen and (max-width: 950px) {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    flex-direction: column;
  }
`;

export const ContactTitle = styled.h3`
  color: #000f38;
  font-weight: 500;
  font-size: 25px;
  align-items: center;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 935px) {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
`;

export const ContactTitleImg = styled.img`
  width: 20px;
  margin-left: 10px;
`;

export const ContactContent = styled.p`
  list-style: 0.3;
  align-items: center;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 935px) {
    margin-bottom: 10px;
    font-size: 16px;
    align-items: center;
    text-align: left;
    padding: 14px 10px;
    line-height: 1.7em;
  }
`;

export const ContactFormInput = styled.input`
  display: block;
  width: 100%;
  padding: 15px;
  background: #eceff1;
  border: 0;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;
`;

export const ContactFormTextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 15px;
  background: #eceff1;
  border: 0;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }
`;

export const ContactFormLabel = styled.label`
  font-size: 16px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }
`;

export const ContactBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin-top: 10px;
    flex-direction: column;
  }
`;

export const ContactBtnLink = styled.button`
  border-radius: 50px;
  background: #546e7a;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-family: "Poppins", sans-serif;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #b0bec5;
    color: #010606;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }
`;

export const ContactSpan = styled.span`
  display: block;
  margin: 10px 0;
`;
