import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { HeroSection } from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Gallery from "../components/Gallery";
import { homeObjOne, homeObjTwo } from "../components/InfoSection/Data";
import BottomInfoSectionBackground from "../components/BottomInfoSection";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <BottomInfoSectionBackground />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
