import poarta1 from "../../images/poarta1_wide.webp";
import poarta2 from "../../images/poarta2_wide.webp";
import poarta3 from "../../images/poarta3.webp";
import poarta4 from "../../images/poarta4.webp";
import poarta5 from "../../images/poarta5.webp";
import poarta6 from "../../images/poarta6.webp";
import poarta7 from "../../images/poarta7.webp";
import poarta8 from "../../images/poarta8.webp";
import poarta9 from "../../images/poarta9.webp";
import poarta10 from "../../images/poarta10.webp";
import poarta11 from "../../images/poarta11.webp";
import poarta12 from "../../images/poarta12.webp";
import poarta13 from "../../images/poarta13.webp";
import poarta14 from "../../images/poarta14.webp";
import copertina1 from "../../images/copertina1.webp";

import scara1 from "../../images/scara1.webp";
import scara2 from "../../images/scara2.webp";
import scara3 from "../../images/scara3.webp";
import scara4 from "../../images/scara4.webp";
import scara5 from "../../images/scara5.webp";
import scara6 from "../../images/scara6.webp";
import scara7 from "../../images/scara7.webp";
import scara8 from "../../images/scara8.webp";
import scara9 from "../../images/scara9.webp";
import scara10 from "../../images/scara10.webp";
import scara11 from "../../images/scara11.webp";
import scara12 from "../../images/scara12.webp";
import scara13 from "../../images/scara13.webp";

import mobilier1 from "../../images/mobilier1.webp";
import mobilier2 from "../../images/mobilier2.webp";
import mobilier3 from "../../images/mobilier3.webp";
import mobilier4 from "../../images/mobilier4.webp";
import mobilier5 from "../../images/mobilier5.webp";
import mobilier6 from "../../images/mobilier6.webp";
import ornament1 from "../../images/ornament1.webp";
import mobilier8 from "../../images/mobilier8.webp";
import mobilier9 from "../../images/mobilier9.webp";
import mobilier10 from "../../images/mobilier10.webp";
import mobilier11 from "../../images/mobilier11.webp";
import mobilier12 from "../../images/mobilier12.webp";
import mobilier13 from "../../images/mobilier13.webp";
import ornament2 from "../../images/ornament2.webp";
import mobilier15 from "../../images/mobilier15.webp";
import mobilier16 from "../../images/mobilier16.webp";
import ornament3 from "../../images/ornament3.webp";
import mobilier17 from "../../images/mobilier17.webp";
import mobilier18 from "../../images/mobilier18.webp";
import mobilier19 from "../../images/mobilier19.webp";
import mobilier20 from "../../images/mobilier20.webp";
import mobilier21 from "../../images/mobilier21.webp";
import mobilier22 from "../../images/mobilier22.webp";
import mobilier23 from "../../images/mobilier23.webp";
import mobilier24 from "../../images/mobilier24.webp";

import sablare1 from "../../images/sablare1.webp";
// import sablare2 from "../../images/sablare2.webp";
import sablare3 from "../../images/sablare3.webp";
import sablare4 from "../../images/sablare4.webp";

const categories = [
  {
    src: poarta1,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta2,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta3,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta4,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta5,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta6,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta7,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta8,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta9,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta10,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta11,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta12,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta13,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: poarta14,
    category: "Porti și garduri",
    alt: "Model poarta",
  },
  {
    src: copertina1,
    category: "Porti și garduri",
    alt: "Model copertina",
  },
  {
    src: scara1,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara2,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara3,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara4,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara5,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara6,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara7,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara8,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara9,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara10,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara11,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara12,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: scara13,
    category: "Scări interioare și balustrade",
    alt: "Model scara interioara",
  },
  {
    src: mobilier1,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier2,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier3,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier4,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier5,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier6,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier8,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier9,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier10,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier11,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier12,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier13,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier15,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier16,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: ornament1,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model ornament",
  },
  {
    src: ornament2,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model ornament",
  },
  {
    src: ornament3,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model ornament",
  },
  {
    src: mobilier17,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier18,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier19,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier20,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier21,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier22,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier23,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: mobilier24,
    category: "Mobilier și decorațiuni (casă/grădină, terase/restaurante)",
    alt: "Model mobilier",
  },
  {
    src: sablare1,
    category: "Sablări pe metal și lemn",
    alt: "Sablare pe metal",
  },
  {
    src: sablare3,
    category: "Sablări pe metal și lemn",
    alt: "Sablare pe lemn",
  },
  {
    src: sablare4,
    category: "Sablări pe metal și lemn",
    alt: "Sablare pe lemn",
  },
];

export default categories;
